import React from 'react'
import HomeIta from "./Views/HomeIta/index"
import Login from "./Views/Login"
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Aux from './Components/Aux'
import Moderation from './Views/Moderation/index'
import Language from './Views/Language'
import HomeEng from './Views/HomeEng'

const App = () => (
  <Router basename="/">
    <Aux>
      <Route exact path="/it" component={HomeIta} />
      <Route exact path="/en" component={HomeEng} />
      <Route exact path="/language" component={Language} />
      <Route exact path="/" component={Login} />
      <Route path="/chat-mod" component={Moderation} />
    </Aux>
  </Router>
)

export default App
