import React, { Component } from 'react'
import { Checkbox, Image, Button, Form, Grid, Header, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'
import { Redirect, useHistory } from 'react-router-dom'
import 'whatwg-fetch'
import banner from '../header.png'
import event from '../cover.png'
import titolo from '../titolo.png'
import ita from '../ita.png'
import eng from '../eng.png'
export default class Language extends Component {

  constructor(props) {
    super(props);

    this.state = {
      redirectToLogin: false,
      goTo: null,
    }

    if (!localStorage.getItem("jwtFinal2023")) {
      this.state.redirectToLogin = true
    }
  }

  render () {
    return (
      <div className='login-form'>
      {this.state.redirectToLogin && (
        <Redirect to="/login" />
      )}
      {this.state.goTo && (
        <Redirect to={this.state.goTo} />
      )}
      {/*
        Heads up! The styles below are necessary for the correct render of this example.
        You can do same with CSS, the main idea is that all the elements up to the `Grid`
        below must have a height of 100%.
      */}
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}</style>
      <Grid stretched style={{ marginBottom: "1vw" }}>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <img src={banner} style={{maxWidth: "100%", backgroundColor: "white"}} />
        </Grid.Column>
      </Grid>
      <Grid centered stretched style={{ marginBottom: "1vw" }}>
        <Grid.Column computer={8} tablet={8} mobile={12}>
          <img src={titolo} style={{maxWidth: "100%"}} />
        </Grid.Column>
      </Grid>
      <Grid
        verticalAlign='middle'
      >
        <Grid.Column computer={10} tablet={16} mobile={16}>
          <img src={event} style={{ maxWidth: "100%", paddingLeft: 40 }} />
        </Grid.Column>
        <Grid.Column computer={6} tablet={16} mobile={16} style={{ maxWidth: 800, padding: "0 30px" }}>
          <p style={{color: '#6c757d', fontSize: '1.2rem'}}>Accedi allo streaming in ITALIANO</p>
          <div style={{display: 'flex', alignItems:'center'}}>
            <Button onClick={() => this.setState({goTo: '/it'})} className="register-button" color='green' size='huge' style={{minWidth: 200, display: 'inline-block' }}>ACCEDI</Button>
            <img style={{height: 40, marginLeft: 20}} src={ita} />
          </div>
          <small style={{color: '#6c757d', display: 'block', fontSize: '0.95rem', marginTop: 10}}>
            È consentito accedere da qualsiasi dispositivo (computer, smartphone o tablet) evitando i dispositivi mobili aziendali (smartphone o tablet) che hanno un traffico limitato.
              Il player video utilizzato per la trasmissione sarà AWS e si consigliano browser come Chrome, Firefox, Edge o Sagari per aprirlo.
          </small>
          <p style={{marginTop: '5rem', color: '#6c757d', fontSize: '1.2rem'}}>Join the ENGLISH streaming</p>
          <div style={{display: 'flex', alignItems:'center'}}>
            <Button onClick={() => this.setState({goTo: '/en'})} className="register-button" color='green' size='huge' style={{minWidth: 200, display: 'inline-block' }}>JOIN</Button>
            <img style={{height: 40, marginLeft: 20}} src={eng} />
          </div>
          <small style={{color: '#6c757d', display: 'block', fontSize: '0.95rem', marginTop: 10}}>
              Log in is permitted from any personal device (computer, smartphone
              or tablet), avoiding the company devices (smartphone or tablet)
              which have limited traffic. The video player used for the
              broadcast will be AWS and Chrome, Firefox, Edge or Safari are
              the suggested browsers to open it.
          </small>
        </Grid.Column>
      </Grid>
    </div>
    )
  }
}